import React, { useMemo } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { LayoutInit } from "./LayoutInit";
import { toAbsoluteUrl } from "../../_helpers";
import { PRIMARY_COLOR } from "../../../app/constants/strings";
import { useIntl } from "react-intl";
import { Typography } from '@material-ui/core';
import moment from "moment";


export function Layout({ children }) {
  const uiService = useHtmlClassService();
  const intl = useIntl();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    };
  }, [uiService]);

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/* <AnimateLoading /> */}

      {/*begin::Main*/}
      {/* <HeaderMobile /> */}

      <div className="d-flex flex-column flex-root" style={{ backgroundColor: PRIMARY_COLOR }}>
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            {/* <Header /> */}
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {/* {layoutProps.subheaderDisplay && <SubHeader />} */}
              {/*begin::Entry*/}


              <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className={layoutProps.contentContainerClasses} style={{ maxWidth: 900 }}>
                  <div className='row mr-2 mt-10 pt-10 mb-5'>
                    <div className='ml-auto'>
                      <img src={toAbsoluteUrl('/assets/logo.svg')} alt="Sonovet"/>
                    </div>
                  </div>
                  {children}
                  <div className='justify-content-center row mt-6'>
                    <Typography style={{ opacity: '0.5', color: "white" }}>©{moment().format("YYYY")}{intl.formatMessage({ id: "FOOTER.TEXT" })}</Typography>
                  </div>
                </div>
                {/*end::Container*/}
              </div>



              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            {/* <Footer /> */}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {/* <QuickUser />
      <QuickPanel />
      <ScrollTop /> */}
      {/* <StickyToolbar /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
      // BLANK LAYOUT
      <div className="d-flex flex-column flex-root">{children}</div>
    );
}
