import MuiPaper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const Paper = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      marginTop: 10,
      marginBottom: 10,
      borderRadius: 10,
      padding: 18,
      // boxShadow: '10px 5px 5px 5px grey',
      '&:not(:last-child)': {
        // borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      }
    },
  })(MuiPaper);

  export default Paper;