import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';

const ExpansionPanelSummary = withStyles({
    root: {
        // maxHeight: 50,
        margin: 0,
        paddingLeft: 10,
        padding: 0,
        
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        // '&:before': {
        //     display: 'none',
        // },
        '&$expanded': {
            margin: 0,
            paddingTop: 18,
            paddingLeft: 10,
            minHeight: 30,
            maxHeight: 30,
        },
        // '&$content': {
        //     marginBottom: 0,
        //     marginTop: 0,
        //     paddingLeft: 10,
        //     maxHeight: 30,
        // }
    },
    expanded: {},
})(MuiExpansionPanelSummary);

export default ExpansionPanelSummary;