/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { loggedIn } from "./modules/Auth/hooks/auth";
import { useReactiveVar } from "@apollo/client"

export function Routes() {
    const authorized = useReactiveVar(loggedIn);

    return (
        <Switch>
            {!authorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Layout>
                    <Route>
                        <AuthPage />
                    </Route>
                </Layout>
            ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

            <Route path="/error" component={ErrorsPage} />
            {/* <Route path="/logout" component={Logout} /> */}


            {!authorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                    <Layout>
                        <BasePage />
                    </Layout>
                )}
        </Switch>
    );
}
