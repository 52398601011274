import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { Typography, TextField } from "@material-ui/core";
import Button from "../../../../components/Button";
import { useHistory } from "react-router-dom";
import { SIGN_IN } from "../../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { setTokens } from '../hooks/auth';

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {

  const history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(initialValues);
  const [error, setError] = useState(false);;

  const [signIn] = useMutation(SIGN_IN);

  const enableLoading = () => {
    setLoading(true);
    setError(false);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleChange = (e, name) => {
    let currentData = { ...data };

    currentData[name] = e.target.value

    setData(currentData)
  }

  const handleSubmit = () => {
    enableLoading();
    signIn({
      variables: {
        input: {
          email: data.email,
          password: data.password
        }
      }
    }).then((res) => {
      setTokens(
        res.data.signIn.access_token,
        res.data.signIn.refresh_token
      );
      disableLoading();
      history.push('/');
    }).catch((e) => {
      disableLoading();
      setError(true);
    });
  }

  return (
    <div className="login-form login-signin m-auto" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-15">
        <Typography variant='h3'>
          {intl.formatMessage({ id: "AUTH.LOGIN.TITLE" })}
        </Typography>
        <Typography className='text-muted'>
          {intl.formatMessage({ id: "AUTH.LOGIN.SUBTITLE" })}
        </Typography>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      {error && (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          })}</div>
        </div>
      )}

      <div className="form-group fv-plugins-icon-container">
        <TextField
          variant='outlined'
          placeholder={intl.formatMessage({ id: "AUTH.EMAIL" })}
          className="form-control h-auto"
          type="email"
          name="email"
          value={data.email}
          onChange={(e) => handleChange(e, "email")}
        />
      </div>
      <div className="form-group fv-plugins-icon-container">
        <TextField
          variant="outlined"
          placeholder={intl.formatMessage({ id: "AUTH.PASSWORD" })}
          className="form-control h-auto"
          type="password"
          name="password"
          value={data.password}
          onChange={(e) => handleChange(e, "password")}
        />
      </div>
      <div className="form-group row">

        <div className="col-sm-12 d-flex mb-5">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="m-auto"
            onClick={() => handleSubmit()}
          >
            <span>{intl.formatMessage({ id: "AUTH.SIGN_IN" })}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>

        </div>
        <div className="col-sm-12 col-lg-6 d-flex">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary ml-auto mr-auto mb-5"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
        </div>
        <div className="col-sm-12 col-lg-6 d-flex">
          <Link
            to="/auth/signup"
            className="text-dark-50 text-hover-primary ml-auto mr-auto mb-5"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.SIGN_UP_BUTTON" />
          </Link>
        </div>
      </div>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(Login);
