

import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles } from '@material-ui/core';
import { TextField, MenuItem, Paper } from "@material-ui/core";
import Downshift from "downshift";
import axios from '../redux/setupAxios';
import { useIntl } from "react-intl";

function AddressInput(props) {

    const intl = useIntl();
    const classes = useStyles();
    const [addressLabel, setAddressLabel] = useState("");
    const [isSearching, setSearching] = useState(false);
    const [searchAddress, setSearchAddress] = useState([])


    const getAddress = (text) => {
        axios
            .get('&boundary.country=ES&layers=address&text=' + text,
        )
            .then((response) => {
                setSearching(false);
                setSearchAddress(response.data.features);
            })
            .catch((e) => {
                console.log("error:", e);
            })
    }

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            if (addressLabel !== '' && isSearching) {
                getAddress(addressLabel);
            }
        }, 500)

        return () => clearTimeout(delaySearch)
    }, [addressLabel, isSearching])


    const selectAddress = (selection) => {
        setSearching(false);
        setAddressLabel(selection.properties.label);
        props.setAddressData(selection);
    }

    return (

        <div className='col-12 mb-4 p-0'>
            <div className={classes.root}>
                <Downshift
                    inputValue={addressLabel}
                    onOuterClick={() => { }}
                    onInputValueChange={event => {
                        setSearching(true);
                        setAddressLabel(event);
                        props.setAddressData(null)
                    }}
                    onChange={(selection) =>
                        selectAddress(selection)
                    }
                    itemToString={(item) => (item ? item.value : '')}
                >
                    {({
                        getInputProps,
                        getItemProps,
                        getMenuProps,
                        isOpen,
                        inputValue,
                        highlightedIndex,
                        selectedItem,
                    }) => (
                        <div className={classes.container}>
                            <TextField
                                fullWidth={true}
                                variant='outlined'
                                id="addressLabel"
                                name="addressLabel"
                                label={intl.formatMessage({ id: "LABEL.INPUT_ADDRESS" })}
                                value={inputValue}
                                onChange={setAddressLabel}
                                InputProps={{
                                    endAdornment: isSearching && <CircularProgress style={{ height: 20, width: 20 }} />
                                }}
                                {...getInputProps()} />
                            <div {...getMenuProps()}>
                                {isOpen
                                    ?
                                    <Paper className={classes.paper} square>
                                        {
                                            searchAddress.map(
                                                (item, index) =>
                                                    <MenuItem
                                                        {...getItemProps({
                                                            key: item.properties.id,
                                                            index,
                                                            item,
                                                            style: {
                                                                backgroundColor:
                                                                    highlightedIndex === index ? 'lightgray' : 'white',
                                                                fontWeight: selectedItem === item ? 'bold' : 'normal',
                                                            },
                                                        })}
                                                    >
                                                        {item.properties.label}
                                                    </MenuItem>
                                            )
                                        }
                                    </Paper>
                                    : null}
                            </div>
                        </div>
                    )}
                </Downshift>
            </div>

        </div>

    );
}

export default AddressInput;


const useStyles = makeStyles(theme => ({

    paper: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },
    root: {
        flexGrow: 1,
        // height: 250
    },
}));