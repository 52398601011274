import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core';
import { useIntl } from "react-intl";
import ExpansionPanel from '../../../../components/ExpansionPanel';
import ExpansionPanelSummary from '../../../../components/ExpansionPanelSummary';
import { ExpansionPanelDetails } from '@material-ui/core';
import { Typography, TextField } from '@material-ui/core';
import Button from '../../../../components/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormDataContext } from '../../../context/FormContext';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    heading: {
        flexShrink: 0,
        fontSize: 20,
        paddingLeft: 15,
        backgroundColor: 'transparent'
    },
}));

export default function ContactPanel(props) {

    const dataContext = useContext(FormDataContext);
    const { data, handleChange, getServices } = dataContext;

    const classes = useStyles();
    const intl = useIntl();

    const createBooking = () => {
        props.handleSubmitting(true);
        props.createBooking({
            variables: {
                bookingInput: {
                    scheduledIn: data.selectedSlot.date,
                    dateToShow: moment(data.selectedSlot.date).add(data.selectedSlot.timePlus, "minutes").format(),
                    services: getServices(),
                    profesionalId: data.selectedSlot.profesionalId,
                    comments: data.comments,
                }
            }
        },
        );
    }

    return (
        <div>
            <ExpansionPanel square expanded={props.expanded === 'panel3'} disabled={props.disabled} onChange={props.handleExpand('panel3')}>
                <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon />}>
                    <span className="material-icons">
                        connect_without_contact
                    </span>
                    <Typography className={classes.heading}>{intl.formatMessage({ id: "TITLE_3" })}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="col-12">
                        <div className='row mt-5'>
                            <div className='col-4 d-flex'>
                                <Typography variant={'body1'} className={'ml-auto mt-auto mb-auto'}>{intl.formatMessage({ id: "LABEL.SELECTED_SERVICE" })}</Typography>
                            </div>
                            <div className='col-8 ml-0'>
                                <Typography variant={'h6'}>{data.service1 && props.data.services.find(s => s.id === data.service1).title}{data.service2 && data.service2 !== 0 ? " y " + props.data.services.find(s => s.id === data.service2).title : null} </Typography>
                            </div>
                        </div>

                        {<div className='row mt-5'>
                            <div className='col-4 d-flex '>
                                <Typography variant={'body1'} className={'ml-auto mt-auto mb-auto'}>{intl.formatMessage({ id: "LABEL.FINAL_PRICE" })}</Typography>
                            </div>
                            <div className='col-8 ml-0'>
                                <Typography variant={'h6'}>{data.selectedSlot && data.selectedSlot.price} €</Typography>
                            </div>

                        </div>}

                        <div className='row mt-5'>
                            <div className='col-4 d-flex'>
                                <Typography variant={'body1'} className={'ml-auto mt-auto mb-auto'}>{intl.formatMessage({ id: "LABEL.SELECTED_DATE" })}</Typography>
                            </div>
                            <div className='col-8 ml-0'>
                                <Typography variant={'h6'}>{data.selectedSlot && moment(data.selectedSlot.date).add(data.selectedSlot.timePlus, "minutes").format('LL[, a las ] HH:mm')}</Typography>
                            </div>

                        </div>


                        <div className='row mt-5'>
                            <div className='col-4 d-flex'>
                                <Typography variant={'body1'} className={'ml-auto mt-auto mb-auto'}>{intl.formatMessage({ id: "LABEL.PET_NAME" })}</Typography>
                            </div>
                            <div className='col-8 ml-0'>
                                <TextField
                                    // multiline
                                    // rows="4"
                                    id="petName"
                                    label={intl.formatMessage({ id: "LABEL.NAME" })}
                                    value={data.petName}
                                    onChange={handleChange('petName')}
                                    margin="normal"
                                    variant="outlined" />
                                {/* </div> */}
                                {/* <Typography variant={'h6'}>{data.selectedSlot && moment(data.selectedSlot.date).format('LL[, a las ] HH:mm')}</Typography> */}
                            </div>

                        </div>

                        <div className='row mt-5'>
                            <div className='col-4 d-flex'>
                                <Typography variant={'body1'} className={'ml-auto mt-auto mb-auto'}>{intl.formatMessage({ id: "LABEL.OWNER_LAST_NAME" })}:</Typography>
                            </div>
                            <div className='col-8 ml-0'>
                                <TextField
                                    // multiline
                                    // rows="4"
                                    id="ownerLastName"
                                    label={intl.formatMessage({ id: "LABEL.OWNER_LAST_NAME" })}
                                    value={data.ownerLastName}
                                    onChange={handleChange('ownerName')}
                                    margin="normal"
                                    variant="outlined" />
                                {/* </div> */}
                                {/* <Typography variant={'h6'}>{data.selectedSlot && moment(data.selectedSlot.date).format('LL[, a las ] HH:mm')}</Typography> */}
                            </div>

                        </div>

                        <div className='row mt-5'>
                            <div className='col-4 d-flex'>
                                <Typography variant={'body1'} className={'ml-auto mt-auto mb-auto'}>{intl.formatMessage({ id: "LABEL.SPECIES" })}:</Typography>
                            </div>
                            <div className='col-8 ml-0'>
                                <TextField
                                    // multiline
                                    // rows="4"
                                    id="species"
                                    label={intl.formatMessage({ id: "LABEL.SPECIES" })}
                                    value={data.species}
                                    onChange={handleChange('species')}
                                    margin="normal"
                                    variant="outlined" />
                                {/* </div> */}
                                {/* <Typography variant={'h6'}>{data.selectedSlot && moment(data.selectedSlot.date).format('LL[, a las ] HH:mm')}</Typography> */}
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-4 d-flex'>
                                <Typography variant={'body1'} className={'ml-auto mt-auto mb-auto'}>{intl.formatMessage({ id: "LABEL.AGE" })}:</Typography>
                            </div>
                            <div className='col-8 ml-0'>
                                <TextField
                                    // multiline
                                    // rows="4"
                                    id="petAge"
                                    label={intl.formatMessage({ id: "LABEL.AGE" })}
                                    value={data.age}
                                    onChange={handleChange('petAge')}
                                    margin="normal"
                                    variant="outlined" />
                                {/* </div> */}
                                {/* <Typography variant={'h6'}>{data.selectedSlot && moment(data.selectedSlot.date).format('LL[, a las ] HH:mm')}</Typography> */}
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-4 d-flex'>
                                <Typography variant={'body1'} className={'ml-auto mt-auto mb-auto'}>{intl.formatMessage({ id: "LABEL.WEIGHT" })}:</Typography>
                            </div>
                            <div className='col-8 ml-0'>
                                <TextField
                                    // multiline
                                    // rows="4"
                                    id="petWeight"
                                    label={intl.formatMessage({ id: "LABEL.WEIGHT" })}
                                    value={data.weight}
                                    onChange={handleChange('petWeight')}
                                    margin="normal"
                                    variant="outlined" />
                                {/* </div> */}
                                {/* <Typography variant={'h6'}>{data.selectedSlot && moment(data.selectedSlot.date).format('LL[, a las ] HH:mm')}</Typography> */}
                            </div>
                        </div>


                        <div className='row mt-5'>
                            <div className='col-12'>
                                <TextField
                                    multiline
                                    rows="4"
                                    id="comments"
                                    label={intl.formatMessage({ id: "LABEL.INPUT_NOTE" })}
                                    value={data.comments}
                                    onChange={handleChange('comments')}
                                    margin="normal"
                                    variant="outlined" />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className={'row justify-content-center'}>
                                <Button variant="contained" color="primary" className={classes.button} onClick={createBooking}>
                                    <Typography variant='h6'>{intl.formatMessage({ id: "BUTTON.CONFIRM_BOOKING" })}</Typography>
                                </Button>
                            </div>
                        </div>

                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}