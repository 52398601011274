import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import { withStyles } from '@material-ui/core/styles';

const ExpansionPanel = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      // maxHeight: ,
      marginTop: 10,
      marginBottom: 10,
      borderRadius: 10,
      padding: 18,
      boxShadow: '10px 5px 5px grey',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$disabled': {
        backgroundColor: '#ffffff80',
      },
    },
    disabled: {
      backgroundColor: '#ffffff80',
    },
  })(MuiExpansionPanel);

  export default ExpansionPanel;