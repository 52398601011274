import { gql } from "@apollo/client";

export const SIGN_IN = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      access_token
      refresh_token
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      access_token
      refresh_token
    }
  }
`;


export const REFRESH_TOKEN = gql`
  mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      access_token
      refresh_token
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    signOut {
      code
      message
    }
  }
`;

export const RECOVER_PASSWORD = gql`
  mutation RecoverPassword($email: String!) {
    pwdRecovery(email: $email) {
      code
      message
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation CreateBooking($bookingInput: BookingInput!) {
    createBooking(input: $bookingInput) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UserInput!) {
    createOrUpdateUser(input: $input) {
      id
      fullName
      nif
      email
      phone
      address{
        id
        direction
        details
        city
        postalCode
        country
      }
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($input: AddressInput!) {
    createOrUpdateAddress(input: $input) {
      id
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation deleteBooking($id: String!) {
    deleteBooking(id: $id) {
      code 
      message
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation updateBooking($input: BookingInput!) {
    updateBooking(input: $input) {
      id
    }
  }
`;

export const UPDATE_BOOKING_SERVICES = gql`
mutation createOrUpdateBookingServiceList($bookingId: String!, $services: [BookingServiceListInput!]) {
    createOrUpdateBookingServiceList(bookingId: $bookingId, services: $services) {
      id
    }
  }
`;