import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core';
import { useIntl } from "react-intl";
import ExpansionPanel from '../../../../components/ExpansionPanel';
import ExpansionPanelSummary from '../../../../components/ExpansionPanelSummary';
import { ExpansionPanelDetails, CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormDataContext } from '../../../context/FormContext';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './style.css';
import MomentLocaleUtils from 'react-day-picker/moment';

import Button from '../../../../components/Button';

import moment from 'moment'
import 'moment/locale/es';
import { useLazyQuery } from "@apollo/client";
import { CHECK_AVAILABILITY } from '../../../../graphql/queries';
moment.locale('es');


export default function OptionsPanel(props) {

    const classes = useStyles();
    const intl = useIntl();

    const dataContext = useContext(FormDataContext);
    const { data, handleChange, getServices } = dataContext;
    const [slots, setSlots] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [specialSchedules, setSchedules] = useState([]);

    const [checkAvailability, { loading }] = useLazyQuery(CHECK_AVAILABILITY, {
        onCompleted(res) {
            if (res.checkAvailability.length > 0) {
                if (moment(res.checkAvailability[0].date).format("E") === "6") {
                    setSlots(res.checkAvailability.filter(s => moment(s.time.split(" - ")[0], "HH:mm").isBefore(moment("14:00", "HH:mm"))))
                } else {
                    setSlots(res.checkAvailability.filter(s => moment(s.date).isAfter(moment().add(60, "minutes"))))
                }
            } else {
                setSlots([])
            }
        }
    });


    useEffect(() => {
        if (props.expanded === "panel1") {
            setSlots(null);
            setSelectedDate(null);
        }
        if (props.expanded === 'panel2' && data.service1) {
            //TODO: find common special schedules in all pros. 
            handleChange('selectedSlot')(null);
        }
    }, [props.expanded]);



    const handleContinue = value => {
        handleChange('selectedSlot')(value);
        props.handleExpand('panel3')(null, true);
    };

    const isDisabled = (date) => {
        if (moment(date).format("E") === "7") {
            return true;
        }
        if (moment(date).isBefore(moment(), "day")) {
            return true;
        }
        return specialSchedules.some((d) => moment(date).isSame(moment(d.day), "day") && d.isClosed);
    }

    const handleCheckAvailability = (value, { disabled }) => {
        if (!disabled) {
            setSelectedDate(value)
            setSlots(null);
            checkAvailability({
                variables: {
                    services: getServices(),
                    date: moment(value).format()
                }
            })
        }
    }

    return (
        <div>
            <ExpansionPanel square expanded={props.expanded === 'panel2'} disabled={props.disabled} onChange={props.handleExpand('panel2')}>
                <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon />}>
                    <span className="material-icons mt-auto mb-auto">
                        calendar_today
                    </span>
                    <Typography className={classes.heading}>{intl.formatMessage({ id: "TITLE_2" })}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="col-12">
                        <div className='row pl-11 mb-4'>
                            <Typography >
                                {intl.formatMessage({ id: "PANEL_2.DESCRIPTION" })}
                            </Typography>
                        </div>

                        <div className='mb-3 row col-12 mt-4 m-0 p-0'>
                            <div className='col-sm-12 col-md-7'>

                                <DayPicker
                                    localeUtils={MomentLocaleUtils}
                                    locale='es'
                                    onDayClick={handleCheckAvailability}
                                    selectedDays={selectedDate}
                                    disabledDays={isDisabled}
                                    onMonthChange={() => setSelectedDate(null)}
                                />
                            </div>
                            <div className='col-sm-12 col-md-5 d-flex'>
                                <div className='ml-auto mr-auto mt-auto mb-auto'>
                                    <div className='row'>
                                        {loading && <CircularProgress />}
                                        {
                                            slots && slots.length > 0 && selectedDate ? slots.map(slot => <div className='col-sm-6 '><Button
                                                fullWidth
                                                variant={data.selectedSlot && moment(data.selectedSlot.date).isSame(slot.date, "hour") ? "contained" : 'outlined'}
                                                color='primary'
                                                // className={classes.timeSlot}
                                                onClick={() => handleContinue(slot)}
                                            >
                                                {moment(slot.date).add(slot.timePlus, "minutes").format("HH:mm")}
                                            </Button></div>

                                            )
                                                :

                                                !loading && slots && selectedDate && <div className="col-12 d-flex text-center ">
                                                    <div className="align-items-center justify-content-center">
                                                        <Typography className="m-auto">
                                                            {intl.formatMessage({ id: "PANEL_2.NO_SLOTS_TEXT" })}
                                                        </Typography>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            !loading && !selectedDate && <div className="col-12 d-flex text-center ">
                                                <div className="align-items-center justify-content-center">
                                                    <Typography className="m-auto">
                                                        {intl.formatMessage({ id: "PANEL_2.SELECT_DATE" })}
                                                    </Typography>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    heading: {
        flexShrink: 0,
        fontSize: 20,
        paddingLeft: 15,
        backgroundColor: 'transparent'
    },
    // timeSlot: {
    //     minWidth: 50,
    //     padding: 0
    // }
}));