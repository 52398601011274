// -- GLOBAL
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'ws://localhost:8080';
export const API_VERSION_PRIVATE = process.env.REACT_APP_API_VERSION_PRIVATE || '/api/private/v1';

export const API_KEY_PRIVATE = process.env.REACT_APP_API_KEY_PRIVATE || 'test';
// export const API_KEY_WEBSOCKET =  process.env.REACT_APP_SOCKET_KEY || 'test';
export const API_KEY_GEOCODE = "5b3ce3597851110001cf624808f70f3465b34848b7dea436fb3321e5"

//COLORS
export const PRIMARY_COLOR = "#60A5D4";