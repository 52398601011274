import React, { useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/';
import PreferencesPanel from './components/preferences_panel';
import OptionsPanel from "./components/options_panel";
import ContactPanel from "./components/contact_panel";
import { Typography, Modal } from '@material-ui/core';
import Button from '../../../components/Button';
import { useQuery, useMutation } from '@apollo/client';
import Header from './components/profile_panel';
import { GET_SERVICES } from '../../../graphql/queries'
import { CREATE_BOOKING, UPDATE_BOOKING_SERVICES } from '../../../graphql/mutations'
import { Link } from "react-router-dom";

export function MainPage() {

  const intl = useIntl();
  const classes = useStyles();

  const [expanded, setExpanded] = useState('panel1');
  const [openModal, setOpen] = useState(false);
  const [openModalCall, setOpenCall] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const { loading, data = { services: [], profesionals: [] } } = useQuery(GET_SERVICES, { variables: { limit: -1, sort: "weight", order: "ASC" } });

  const [handleCreateBooking] = useMutation(CREATE_BOOKING, {
    onCompleted(res) {
      setError(false);
      setOpen(true);
      setSubmitting(false);
    }, onError(err) {
      setError(true);
      setErrorText(err.graphQLErrors[0].extensions.code);
      setOpen(true);
      setSubmitting(false);
    }
  });

  const handleExpand = panel => (event, isExpanded) => {
    if (panel !== expanded) {
      setExpanded(isExpanded ? panel : false);
    }
  };

  const handleReload = () => {
    setOpen(!openModal);
    window.location.reload()
  }

  const handleModalCall = () => {
    setOpenCall(true);
  }

  return (
    <div>
      <div style={{ justifyContent: "flex-end", marginRight: 5, marginBottom: 5 }}>
        <Link to="/bookings" className="d-flex justify-content-end">
          <span className="material-icons text-white" style={{ width: 35 }}>
            list_view
          </span>
          <Typography
            // variant="h5" 
            className="text-white mt-auto mb-auto"
          >
            {intl.formatMessage({ id: "TITLE_BOOKINGS" })}
          </Typography>
        </Link>
      </div>
      <Header
        handleExpand={handleExpand}
        expanded={expanded}
        disabled={expanded}
      />
      <PreferencesPanel
        handleExpand={handleExpand}
        expanded={expanded}
        disabled={expanded === 'panel0'}
        data={data}
        loading={loading}
        handleModalCall={handleModalCall}
      />
      <OptionsPanel
        handleExpand={handleExpand}
        expanded={expanded}
        disabled={expanded === 'panel1' || expanded === 'panel0'}
        data={data}
      />
      <ContactPanel
        handleExpand={handleExpand}
        data={data}
        createBooking={handleCreateBooking}
        handleSubmitting={setSubmitting}
        expanded={expanded}
        disabled={expanded === 'panel2' || expanded === 'panel1' || expanded === 'panel0'}
      />


      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openModal}
        onClose={null}
      >

        <div className={classes.paper}>
          <div style={{ justifyContent: 'right', }}>
            <Typography id="modal-title" style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
              {error ? intl.formatMessage({ id: "MODAL_ERROR.TITLE" }) : intl.formatMessage({ id: "MODAL_CONFIRM.TITLE" })}
            </Typography>
            <Typography id="simple-modal-description" style={{ textAlign: 'center' }}>
              {error ? errorText : intl.formatMessage({ id: "MODAL_CONFIRM.DESCRIPTION" })}
            </Typography>
            <div className={'row justify-content-center'} >
              <Button variant="contained" color="primary" className={classes.button} onClick={handleReload} disabled={submitting}>
                <Typography variant='h6'>{intl.formatMessage({ id: "MODAL_CONFIRN.BUTTON_TEXT" })}</Typography>
                {submitting && <span className="ml-3 spinner spinner-white"></span>}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openModalCall}
        onClose={null}
      >

        <div className={classes.paper}>
          <div style={{ justifyContent: 'right', }}>
            <Typography id="modal-title" style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
              {intl.formatMessage({ id: "MODAL_CALL_TO_BOOK.TITLE" })}
            </Typography>
            <Typography id="simple-modal-description" style={{ textAlign: 'center' }}>
              {intl.formatMessage({ id: "MODAL_CALL_TO_BOOK.DESCRIPTION" })}
            </Typography>
            <div className={'row justify-content-center'} >
              <Button variant="contained" color="primary" className={classes.button} onClick={() => setOpenCall(false)} >
                <Typography variant='h6'>{intl.formatMessage({ id: "MODAL_CONFIRN.BUTTON_TEXT" })}</Typography>
                {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    borderRadius: 10,
  },
}));