import { client } from "../../../../graphql";
import { REFRESH_TOKEN } from "../../../../graphql/mutations";
import * as jose from "node-jose";
import { BASE_URL } from "../../../constants/strings";
import { makeVar } from '@apollo/client';


const ATK = "atk"; //Access Token
const RTK = "rtk"; //Refresh Token
const TOKEN_VALID = 0;
const TOKEN_INVALID = 1;
const TOKEN_EXPIRED = 2;
const TOKEN_NOT_FOUND = 3;
const TIME_BUFFER = 3;
let keystore = null;
let lock = false;

export const loggedIn = makeVar(localStorage.getItem(ATK) ? true : false);

const getKeystore = async () => {
    if (!keystore) {
        return await fetch(
            BASE_URL + '/graphql',
            {
                method: 'POST',
                body: JSON.stringify({
                    query: `
                query getKeys {
                  getKeys 
                }
              `
                }),
                headers: {
                    'Content-type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY_GRAPHQL
                },
            }
        )
            .then(async (res) => {
                let response = await res.json();
                keystore = await jose.JWK.asKeyStore(response.data.getKeys.keys).catch((_) => null);
                return keystore;
            })
            .catch((_) => null);
    } else {
        return keystore;
    }


};

export const isValid = async (token) => {
    if (token) {
        try {
            return await jose.JWS.createVerify(await getKeystore())
                .verify(token)
                .then((result) => {
                    const payload = JSON.parse(result.payload.toString("utf8"));
                    setUserId(payload.userId);
                    return payload.exp * 1000 < Date.now() + 60000 * TIME_BUFFER
                        ? TOKEN_EXPIRED
                        : TOKEN_VALID;
                });
        } catch {
            return TOKEN_INVALID;
        }
    } else return TOKEN_NOT_FOUND;
};

export const refreshToken = async (token, refreshToken) => {
    await client
        .mutate({
            variables: {
                input: {
                    refreshToken: refreshToken,
                    pushToken: token,
                    client: "",
                    version: "",
                },
            },
            mutation: REFRESH_TOKEN,
        })
        .then((res) => {
            setTokens(
                res.data.refreshToken.access_token,
                res.data.refreshToken.refresh_token
            );
        });
};

export const getToken = async () => {
    const t = getATK();

    if (!lock) {
        switch (await isValid(t)) {
            case TOKEN_VALID:
                return getATK();
            case TOKEN_EXPIRED:
                lock = true;
                return await refreshToken(t, getRTK())
                    .then(() => {
                        lock = false;
                        return getATK();
                    })
                    .catch(() => {
                        lock = false;
                        return resetTokens();
                    });
            case TOKEN_INVALID:
            case TOKEN_NOT_FOUND:
            default:
                //Router.push("/auth/login");
                return resetTokens();
        }
    }
    return t;
};

export const setATK = (atk) => {
    loggedIn(true);
    localStorage.setItem(ATK, atk);
};

export const getATK = () => {
    return localStorage.getItem(ATK);
};

export const setRTK = (rtk) => {
    loggedIn(true);
    localStorage.setItem(RTK, rtk);
};

export const getRTK = () => {
    return localStorage.getItem(RTK);
};

export const setUserId = (id) => {
    localStorage.setItem("uid", id);
};

export const getUserId = () => {
    return localStorage.getItem("uid");
};

export const setTokens = (atk, rtk) => {
    loggedIn(true);
    setATK(atk);
    setRTK(rtk);
};

export const resetTokens = () => {
    loggedIn(false);
    localStorage.removeItem(ATK);
    localStorage.removeItem(RTK);
    localStorage.removeItem("uid");
    return "";
};
