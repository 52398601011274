import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/';
import { Typography, Modal } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_BOOKING_SERVICES } from '../../../../graphql/mutations'
import { GET_SERVICES } from '../../../../graphql/queries'
import Button from '../../../../components/Button';
import { MenuItem } from '@material-ui/core';

export default function EditBooking(props) {
    console.log(props)

    const intl = useIntl();
    const classes = useStyles();

    const [openModal, setOpen] = useState(false);
    const [booking, setBooking] = useState({
        services: []
    });
    const [service1, setFirstService] = useState((props.booking && props.booking.services[0] && props.booking.services[0].service.id) ?? null)
    const [service2, setSecondService] = useState((props.booking && props.booking.services[1] && props.booking.services[1].service.id) ?? 0)
    const [filteredServices, setFilteredServices] = useState([]);
    const [error, setError] = useState(false);

    const { data = { services: [] } } = useQuery(GET_SERVICES, { variables: { limit: -1, sort: "weight", order: "ASC" } });

    const [updateBookingServices, { loading }] = useMutation(UPDATE_BOOKING_SERVICES, {
        onCompleted(res) {
            setOpen(false);
            props.refresh();
            setError(false);
        }, onError() {
            setError(true);
        }
    });

    const handleChange = (event) => {
        setSecondService(event.target.value);
    }

    const handleChangeService = (event) => {
        // getAvailableServices(event.target.value);

        setFirstService(event.target.value);
        setSecondService(0);
    }

    useEffect(() => {
        if (data.services && openModal) {
            getAvailableServices();
            console.log(props.booking && props.booking.profesional && props.booking.profesional.id)
        }

    }, [openModal]);


    const getAvailableServices = () => {
        let services = [];

        data.services.map(s => {
            if (s.profesionals.some(p => p.id === props.booking.profesional.id)) {
                services.push(s);
            }
        })
        setFilteredServices(services);
    }

    const handleUptate = () => {

        let services = [
            {
                remindIn: null,
                reminder: false,
                petName: null,
                serviceId: service1,
            }
        ];

        if (service2 !== 0) {
            services.push({
                remindIn: null,
                reminder: false,
                petName: null,
                serviceId: service2,
            })
        }

        updateBookingServices({
            variables: {
                bookingId: props.booking.id,
                services: services
            }
        })
    }

    return (
        <div>
            <span onClick={() => setOpen(true)}>{props.children}</span>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openModal}
                onClose={() => setOpen(false)}
            >

                <div className={classes.paper}>
                    <div className="row justify-content-center pr-5">

                        <div className={'col-12 mr-2 ml-2'}>
                            <Typography id="modal-title" variant="h5" style={{ fontWeight: 'bold' }}>
                                {intl.formatMessage({ id: "MODAL_EDIT.TITLE" })}
                            </Typography>
                            <Typography id="simple-modal-description" >
                                {intl.formatMessage({ id: "MODAL_EDIT.DESCRIPTION" })}
                            </Typography>
                        </div>
                        <div className={'row mt-5 p-0'}>
                            <div className={' col-sm-12'}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-simple">
                                        {/* ref={inputLabel}ref={inputLabel} */}
                                        {intl.formatMessage({ id: "LABEL.SELECT_SERVICES" })}
                                    </InputLabel>
                                    <Select
                                        value={service1}
                                        onChange={handleChangeService}
                                        input={<OutlinedInput labelWidth={210} name="service1" id="outlined-age-simple" />}
                                    >
                                        {filteredServices.map(service => <MenuItem value={service.id}>{service.title}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={' col-sm-12'}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-simple">
                                        {/* ref={inputLabel}ref={inputLabel} */}
                                        {intl.formatMessage({ id: "LABEL.SELECT_SERVICES_SECOND" })}
                                    </InputLabel>
                                    <Select
                                        disabled={filteredServices.length === 0}
                                        value={service2}
                                        onChange={handleChange}
                                        input={<OutlinedInput labelWidth={270} name="service2" id="outlined-age-simple" />}
                                    >
                                        <MenuItem value={0}>{"Continuar con un servicio"}</MenuItem>
                                        {filteredServices.map(service => <MenuItem value={service.id}>{service.title}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={' col-md-12 col-sm-12 '}>
                                <Typography className="ml-3">{intl.formatMessage({ id: "TEXT.NO_BOOKING_AVAILABLE" })}</Typography>
                            </div>

                        </div>

                        <div className={'row  col-12 '} >
                            <div className="col-md-12 col-sm-12 d-flex justify-content-center ">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="mr-4"
                                    disabled={service1 === null || loading}
                                    onClick={handleUptate}
                                >
                                    <Typography variant='h6'>{intl.formatMessage({ id: "MODAL_EDIT.BUTTON_TEXT" })}</Typography>
                                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                                </Button>
                                <Button variant="outlined" color="primary"
                                    // className="ml-auto mr-auto"
                                    onClick={() => setOpen(false)}
                                >
                                    <Typography variant='h6'>{intl.formatMessage({ id: "MODAL_EDIT.BUTON_CLOSE" })}</Typography>
                                </Button>
                            </div>
                            {
                                error && <div className={' col-md-12 col-sm-12 mt-3 d-flex'}>
                                    <Typography className="ml-auto mr-auto">{intl.formatMessage({ id: "TEXT.EDIT_BOOKING_ERROR" })}</Typography>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        position: 'absolute',
        maxWidth: 570,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        borderRadius: 10,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
}));