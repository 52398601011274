import React, { useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/';
import { Typography, Modal } from '@material-ui/core';
import Button from '../../../../components/Button';
import DownloadIcon from "@material-ui/icons/SaveAlt";

export default function DownloadFiles(props) {

    const intl = useIntl();
    const classes = useStyles();

    const [openModal, setOpen] = useState(false);

    return (
        <div>
            <span onClick={() => setOpen(true)}>{props.children}</span>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openModal}
                onClose={() => setOpen(false)}
            >

                <div className={classes.paper}>
                    <div className="row justify-content-center pr-5">

                        <div className={'col-12 mr-2 ml-2'}>
                            <Typography id="modal-title" variant="h5" style={{ fontWeight: 'bold' }}>
                                {intl.formatMessage({ id: "MODAL_DOWNLOAD.TITLE" })}
                            </Typography>
                        </div>

                        <div className={'row col-12 mr-2 ml-2 mt-3'}>
                            {
                                props?.files?.map((file) =>
                                    <div className={'col-6 mt-3 mb-1 d-flex'} style={{ cursor: "pointer" }}>
                                        <a href={`${process.env.REACT_APP_BASE_URL}/media/${file.id}`} className={'d-flex'}>
                                            <DownloadIcon className="mr-2" />
                                            <p style={{ textDecoration: 'underline', fontSize: 15 }}>
                                                {file.fileName}
                                            </p>
                                        </a>
                                    </div>)
                            }
                        </div>

                        <div className={'row  col-12 justify-content-center'} >

                            {/* <div className="col-sm-12 justify-content-center"> */}
                            <Button variant="outlined" color="primary"
                                onClick={() => setOpen(false)}
                            >
                                <Typography variant='h6'>{intl.formatMessage({ id: "MODAL_DOWNLOAD.BUTTON_CLOSE" })}</Typography>
                            </Button>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        position: 'absolute',
        maxWidth: 500,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        borderRadius: 10,
    },
}));