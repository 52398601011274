import React from 'react';
import MuiButton from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles";

const Button = props => {

  const ThemeButton = withStyles(theme => ({
    root: {
      lineHeight: 1.35,
      paddingTop: 15, 
      paddingBottom: 15, 
      paddingLeft: 60,
      paddingRight: 60,
      color: 'white',
      fontSize: 18,
      marginTop: 20,
      textTransform: 'none',
      border: '0.5px solid',
      borderColor: '#1e577e3b',
      '&:disabled': {
        backgroundColor: '#60a5d4',
        color: 'white',
        opacity: '0.5'
      },
    },
    outlined: {
      color: "#60a5d4",
      border: '1px solid',
      borderColor: '#60a5d4',

    }
  }))(MuiButton);

  return <ThemeButton {...props} />;

};

export default Button;
