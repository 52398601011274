import { gql } from "@apollo/client";

export const GET_SERVICES = gql`
  query GetServices($limit: Int, $sort: SortServices,$order: orderTypes){
    services(limit: $limit, sort: $sort, order: $order) {
      id,
      active,
      title,
      weight, 
      durationTime,
      callToBook,
      profesionals{
        fullName
        id
        specialSchedules{
          openFrom 
          openTo
          isClosed
          day
        }
      }
    }
  }
`;

export const CHECK_AVAILABILITY = gql`
  query checkAvailability($services: [servicesJSON], $profesionalId: String, $date: String){
    checkAvailability(services: $services, profesionalId: $profesionalId, date: $date) {
     weight
     time 
     available
     date
     profesionalId
     price
     timePlus
    }
  }
`;

export const GET_PROFILE_DATA = gql`
query profileData($id: String){
  user(id: $id) {
   id
   fullName
   nif
   email
   phone
   address{
     id
     direction
     details
     city
     postalCode
     country
   }
  }
}
`;

export const GET_BOOKINGS = gql`
query GetBookings($filter: bookingFilter, $limit: Int, $offset: Int, $sort: SortBooking, $order: orderTypes){
  bookings(filter: $filter, limit: $limit, offset: $offset, order: $order, sort: $sort ) {
   id
   scheduledIn
   scheduledOut
   comments
   status 
   petName
   dateToShow
   modifiedTime
   files{
     id 
     fileName
   }
   profesional{
     id
     fullName
   }
   services{
     id 
     service{
       id
       title
     }
     
   }
  }
  countBookings(filter: $filter,)
}
`;