import React, { useState, useEffect } from "react";
import { makeStyles, TextField, Typography } from '@material-ui/core';
import { useIntl } from "react-intl";
import { getUserId } from "../../../modules/Auth/hooks/auth";
import ExpansionPanel from '../../../../components/ExpansionPanel';
import ExpansionPanelSummary from '../../../../components/ExpansionPanelSummary';
import { ExpansionPanelDetails, CircularProgress } from '@material-ui/core';
import Button from '../../../../components/Button';
import AddressInput from '../../../../components/AddressInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GET_PROFILE_DATA } from "../../../../graphql/queries";
import { UPDATE_USER, UPDATE_ADDRESS } from "../../../../graphql/mutations";
import { useMutation, useLazyQuery } from "@apollo/client";


export default function Header(props) {

    const classes = useStyles();
    const intl = useIntl();

    const [data, setData] = useState(null);
    const [valid, setValid] = useState(false);
    const [addressData, setAddressData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [validations, setValidations] = useState({
        fullName: true,
        email: true,
        phone: true,
        nif: true,
        password: true,
        confirmPassword: true,
    });

    const [getData] = useLazyQuery(GET_PROFILE_DATA, {
        onCompleted(res) {
            setData({ ...res.user, password: "", confirmPassword: "" });
            setLoading(false)
        }
    });

    const [updateUser] = useMutation(UPDATE_USER, {
        onCompleted(res) {
            setData(res.createOrUpdateUser);
            setValid(false);
            setError(false);
            setLoading(false);
            props.handleExpand('panel1')(null, true)
        }, onError() {
            setError(true);
        }
    });

    const [updateAddress] = useMutation(UPDATE_ADDRESS, {
        onCompleted(res) {
            // setValid(false);
            // setData(res.createOrUpdateUser);
        }, onError() {
            setError(true);
        }
    });

    useEffect(() => {
        if (props.expanded === "panel0" && loading) {
            getData({
                variables: {
                    id: getUserId()
                }
            });
        }
    }, [props.expanded]);

    const handleChange = (name) => (event) => {
        let currentData = { ...data };
        currentData[name] = event.target.value;
        let currentValidations = { ...validations };
        currentValidations[name] = validate(name, currentData[name]);
        setData(currentData);
        setValid(setValidValue(currentData));
        setValidations(currentValidations);
    }

    const validate = (name, value) => {
        let emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        let nifPattern = /^[0-9]{8,8}[A-Za-z]$/;
        switch (name) {
            case 'firstName':
                return value !== null;
            case 'phone':
                return value !== null;
            case 'email':
                return value !== null && emailPattern.test(value);
            case 'password':
                return (value && value !== null) || !value;
            case 'confirmPassword':
                return (value && value !== null && value === data.password) || !value;
            case 'nif':
            default:
                return true;
        }
    };

    const setValidValue = (currentData) => {
        let valid = true;
        for (let value of Object.entries(currentData)) {
            valid = valid && validate(value[0], value[1]);
            if (!valid) { break; }
        }

        valid = valid && currentData.password === currentData.confirmPassword;
        return valid;
    };

    const handleEditAddress = (newAddress) => {
        if (newAddress) {
            setAddressData(newAddress);
            let currentAddress = { ...data.address };
            currentAddress.direction = newAddress.properties.street;
            currentAddress.details = newAddress.properties.housenumber;
            currentAddress.postalCode = newAddress.properties.postalcode;
            currentAddress.city = newAddress.properties.locality;
            currentAddress.country = newAddress.properties.country;
            setData({ ...data, address: currentAddress })
        }
    }

    const handleSave = () => {

        if (addressData) {
            updateAddress({
                variables: {
                    input: {
                        id: data.address.id,
                        direction: data.address.direction,
                        details: data.address.details,
                        city: data.address.city,
                        postalCode: data.address.postalCode,
                        country: data.address.country,
                        location: addressData.geometry,
                        userId: data.id
                    }
                }
            })
        }

        const input = {
            id: data.id,
            email: data.email,
            phone: data.phone.toString(),
            fullName: data.fullName,
            nif: data.nif,
            password: data.password
        };

        if (data.password === "") {
            delete input.password
        }
        updateUser({
            variables: {
                input
            }
        });
    }



    return (
        <div>
            <ExpansionPanel square expanded={props.expanded === 'panel0'} onChange={props.handleExpand('panel0')}>
                <ExpansionPanelSummary aria-controls="panel0d-content" id="panel0d-header" expandIcon={props.expanded === 'panel0' ? null : <ExpandMoreIcon />}>
                    <span className="material-icons mt-auto mb-auto">
                        person
                    </span>
                    <Typography className={classes.heading}>{intl.formatMessage({ id: "TITLE_0" })}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails >

                    <div className='col-sm-12'>
                        {
                            !loading && data ?
                                <>
                                    <div className="col-12 d-flex justify-content-between p-0">
                                        <Typography className={'mt-auto'}>
                                            {intl.formatMessage({ id: "AUTH.REGISTER.USER_DATA" })}
                                        </Typography>

                                    </div>

                                    <div className={'row'}>
                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                id="fullName"
                                                label={intl.formatMessage({ id: "LABEL.INPUT_NAME" })}
                                                className={classes.textField}
                                                value={data && data.fullName}
                                                onChange={handleChange('fullName')}
                                                margin="normal"
                                                variant="outlined"
                                                error={!validations.fullName}
                                                helperText={validations.fullName ?
                                                    ''
                                                    :
                                                    intl.formatMessage({ id: "ERROR.NAME" })}
                                            />
                                        </div>

                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                id="email"
                                                label={intl.formatMessage({ id: "LABEL.INPUT_EMAIL" })}
                                                className={classes.textField}
                                                value={data && data.email}
                                                onChange={handleChange('email')}
                                                margin="normal"
                                                variant="outlined"
                                                error={!validations.email}
                                                helperText={validations.email ?
                                                    ''
                                                    :
                                                    intl.formatMessage({ id: "ERROR.EMAIL" })}
                                            />
                                        </div>

                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                id="phone"
                                                type={"number"}
                                                label={intl.formatMessage({ id: "LABEL.INPUT_PHONE" })}
                                                className={classes.textField}
                                                value={data && data.phone}
                                                onChange={handleChange('phone')}
                                                margin="normal"
                                                variant="outlined"
                                                error={!validations.phone}
                                                helperText={validations.phone ?
                                                    ''
                                                    :
                                                    intl.formatMessage({ id: "ERROR.PHONE" })}
                                            />
                                        </div>

                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                id="nif"
                                                label={intl.formatMessage({ id: "LABEL.INPUT_DNI" })}
                                                className={classes.textField}
                                                value={data && data.nif}
                                                onChange={handleChange('nif')}
                                                margin="normal"
                                                variant="outlined"
                                                error={!validations.nif}
                                                helperText={validations.nif ?
                                                    ''
                                                    :
                                                    intl.formatMessage({ id: "ERROR.DNI" })}
                                            />
                                        </div>

                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                id="password"
                                                label={intl.formatMessage({ id: "LABEL.NEW_PASSWORD" })}
                                                className={classes.textField}
                                                value={data?.password ?? ""}
                                                onChange={handleChange('password')}
                                                margin="normal"
                                                variant="outlined"
                                                type="password"
                                                error={!validations.password}
                                                helperText={validations.password ?
                                                    ''
                                                    :
                                                    intl.formatMessage({ id: "ERROR.PASSWORD" })}
                                            />
                                        </div>

                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                id="confirmPassword"
                                                label={intl.formatMessage({ id: "LABEL.NEW_PASSWORD_CONFIRM" })}
                                                className={classes.textField}
                                                value={data?.confirmPassword ?? ""}
                                                onChange={handleChange('confirmPassword')}
                                                margin="normal"
                                                variant="outlined"
                                                type="password"
                                                error={!validations.confirmPassword}
                                                helperText={validations.confirmPassword ?
                                                    ''
                                                    :
                                                    intl.formatMessage({ id: "ERROR.PASSWORD" })}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="col-12 mt-5 p-0">
                                        <Typography>
                                            {intl.formatMessage({ id: "AUTH.REGISTER.ADDRESS_DATA" })}
                                        </Typography>
                                    </div> */}

                                    {/* <div className="col-12 mt-5 p-0">
                                        <AddressInput
                                            setAddressData={handleEditAddress}
                                        />
                                    </div> */}

                                    {/* <div className={'row'}>
                                        <div className={'col-md-12 col-sm-12'}>
                                            <TextField
                                                disabled
                                                id="direction"
                                                label={intl.formatMessage({ id: "LABEL.INPUT_PERSONAL_ADDRESS" })}
                                                className={classes.textField}
                                                value={data && data.address.direction}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </div>

                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                disabled
                                                id="details"
                                                label={intl.formatMessage({ id: "LABEL.INPUT_ADDRESS_DETAILS" })}
                                                className={classes.textField}
                                                value={data && data.address.details}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </div>

                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                disabled
                                                id="postalCode"
                                                label={intl.formatMessage({ id: "LABEL.INPUT_POSTALCODE" })}
                                                className={classes.textField}
                                                value={data && data.address.postalCode}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </div>

                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                disabled
                                                id="city"
                                                label={intl.formatMessage({ id: "LABEL.INPUT_CITY" })}
                                                className={classes.textField}
                                                value={data && data.address.city}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className={'col-md-6 col-sm-12'}>
                                            <TextField
                                                disabled
                                                id="country"
                                                label={intl.formatMessage({ id: "LABEL.INPUT_COUNTRY" })}
                                                className={classes.textField}
                                                value={ data && data.address.country}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </div>
                                    </div> */}
                                </>
                                :
                                <div className="d-flex justify-content-center">
                                    <CircularProgress />
                                </div>
                        }
                        <div className={'col-12 d-flex'}>
                            {error && (
                                <div className="alert alert-custom alert-light-danger alert-dismissible ml-auto mr-auto mb-0 mt-2">
                                    <div className="alert-text font-weight-bold">{intl.formatMessage({
                                        id: "PROFILE.VALIDATION.DATA",
                                    })}</div>
                                </div>
                            )}
                        </div>

                        {/* <div className={'row'}> */}
                        <div className={'col-sm-12 d-flex'}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={'mr-auto ml-auto'}
                                onClick={handleSave}
                                disabled={!valid}
                            >
                                <Typography variant='h6'>{intl.formatMessage({ id: "BUTTON.SAVE" })}</Typography>
                            </Button>
                        </div>
                        <div className="d-flex col-sm-12">

                            <Typography
                                onClick={() => props.handleExpand('panel1')(null, true)}
                                variant='h6'
                                style={{ cursor: "pointer" }}
                                className="text-dark-50 text-hover-primary mt-5 ml-auto mr-auto"
                            >
                                {intl.formatMessage({ id: "BUTTON.EXIT" })}
                            </Typography>
                        </div>
                        {/* </div> */}
                    </div>

                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div >
    );
}

const useStyles = makeStyles(theme => ({
    heading: {
        flexShrink: 0,
        fontSize: 20,
        paddingLeft: 15,
        backgroundColor: 'transparent',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
}));