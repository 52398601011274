import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { makeStyles, CircularProgress, IconButton, TableFooter, TablePagination, TableContainer, TableSortLabel, TextField, InputAdornment } from '@material-ui/core/';
import { Typography } from '@material-ui/core';
import Paper from '../../../components/Paper';
import { getUserId, resetTokens } from "../../modules/Auth/hooks/auth";
import SearchIcon from '@material-ui/icons/Search';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_BOOKINGS } from '../../../graphql/queries'
import { LOGOUT } from '../../../graphql/mutations';
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment/locale/es';
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import ModalDelete from "./components/ModalDelete";
import ModalDownload from "./components/ModalDownload";
import ModalEdit from "./components/ModalEdit";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

moment.locale('es');

export function BookingsPage() {

    const intl = useIntl();
    const classes = useStyles();

    const [data, setData] = useState();
    const [pagination, setPagination] = useState({ page: 0, rows: 10, total: 0, pages: 0 });
    const [sort, setSort] = useState({ sort: "scheduledIn", asc: false });
    const [search, setSearch] = useState("");

    const [getBookings, { error }] = useLazyQuery(GET_BOOKINGS, {
        onCompleted(res) {
            setData(res);
        }
    });

    const [logout] = useMutation(LOGOUT, {
        onCompleted(res) {
            resetTokens()
        }
    });

    useEffect(() => {
        getBookings({
            variables: {
                filter: {
                    userId: getUserId(),
                    petName: search,
                },
                limit: pagination.rows,
                offset: pagination.page * pagination.rows,
                sort: sort.sort,
                order: sort.asc ? "ASC" : "DESC"
            }
        });
    }, []);


    const handleChangePagination = (name, value) => {
        let currentPagination = { ...pagination };
        currentPagination[name] = value;
        setPagination(currentPagination);
    }

    const handleChangePage = (event, newPage) => {
        setPagination({ ...pagination, "page": newPage });
    }

    const handleSort = (newSort) => {
        let asc = true;

        if (sort.sort == newSort) {
            asc = !sort.asc;
        }

        setSort({ sort: newSort, asc: asc });
    }

    useEffect(() => {
        getBookings({
            variables: {
                filter: {
                    userId: getUserId(),
                    petName: search,
                },
                limit: parseInt(pagination.rows),
                offset: parseInt(pagination.page) * parseInt(pagination.rows),
                sort: sort.sort,
                order: sort.asc ? "ASC" : "DESC"
            }
        });
    }, [pagination, sort, search]);

    return (
        <div>
            <ModalEdit />
            <div style={{ justifyContent: "flex-end", marginRight: 5 }}>
                <Link to="/" className="d-flex justify-content-end">
                    <span className="material-icons text-white" style={{ width: 35 }}>
                        view_agenda
                    </span>
                    <Typography
                        // variant="h5" 
                        className="text-white mt-auto mb-auto"
                    >
                        {intl.formatMessage({ id: "NAVIGATION.MAIN_PAGE" })}
                    </Typography>
                </Link>
            </div>

            <div style={{ justifyContent: "flex-end", marginRight: 5, marginTop: 10 }}>
                <div className="d-flex justify-content-end" onClick={logout} style={{ cursor: "pointer" }}>
                    <span className="material-icons text-white" style={{ width: 35 }}>
                        power_settings_new
                    </span>
                    <Typography
                        // variant="h5" 
                        className="text-white mt-auto mb-auto"
                    >
                        {intl.formatMessage({ id: "BUTTON.LOGOUT" })}
                    </Typography>
                </div>
            </div>

            <Paper>
                <div className="d-flex row p-4">
                    <div className="col-md-6 col-sm-12 d-flex">
                        <span className="material-icons mt-auto mb-auto">
                            date_range
                    </span>
                        <Typography className={classes.heading} >{intl.formatMessage({ id: "TITLE_BOOKINGS" })}</Typography>
                    </div>
                </div>

                {data && data.bookings ?
                    data.bookings.length > 0 ?
                        <div className={'row m-1'}>

                            <div className={"col-4 ml-auto"}>
                                <TextField
                                    // multiline
                                    // rows="4"
                                    id="search"
                                    // label={intl.formatMessage({ id: "LABEL.SEARCH" })}
                                    placeholder={intl.formatMessage({ id: "LABEL.SEARCH" })}
                                    value={data.petName}
                                    onChange={(e) => setSearch(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </div>

                            <TableContainer >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {/* <TableSortLabel> */}
                                                <Typography variant={"h6"} >{intl.formatMessage({ id: "SERVICES_TXT_TABLE" })}</Typography>
                                                {/* </TableSortLabel> */}
                                            </TableCell>
                                            <TableCell>
                                                {/* <TableSortLabel> */}
                                                <Typography variant={"h6"} >{intl.formatMessage({ id: "PROFESIONAL_TXT_TABLE" })}</Typography>
                                                {/* </TableSortLabel> */}
                                            </TableCell>
                                            <TableCell>
                                                {/* <TableSortLabel> */}
                                                <Typography variant={"h6"} >{intl.formatMessage({ id: "PETNAME_TXT_TABLE" })}</Typography>
                                                {/* </TableSortLabel> */}
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel active={sort.sort === "scheduledIn"} direction={sort.asc ? 'asc' : 'desc'} onClick={() => handleSort("scheduledIn")} >
                                                    <Typography variant={"h6"} > {intl.formatMessage({ id: "DATE_TXT_TABLE" })}</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                {/* <TableSortLabel> */}
                                                <Typography variant={"h6"} >{intl.formatMessage({ id: "ACTIONS_TXT_TABLE" })}</Typography>
                                                {/* </TableSortLabel> */}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.bookings.map(booking => <TableRow style={moment(booking.scheduledIn).isBefore(moment()) ? { backgroundColor: "#8080801f" } : {}}>
                                                <TableCell>
                                                    {booking.services.map(service => <p>{service.service.title}</p>)}
                                                </TableCell>
                                                <TableCell>
                                                    {booking.profesional.fullName}
                                                </TableCell>
                                                <TableCell>
                                                    {booking.petName}
                                                </TableCell>
                                                <TableCell>
                                                    {booking.modifiedTime !== null ? moment(booking.modifiedTime).format("LL, [a las] HH:mm") : moment(booking.dateToShow).format("LL, [a las] HH:mm")}
                                                </TableCell>
                                                <TableCell>
                                                    <div className="d-flex">
                                                        {
                                                            moment(booking.scheduledIn).isAfter(moment()) &&
                                                            <>
                                                                <ModalEdit
                                                                    booking={booking}
                                                                    refresh={() => getBookings({
                                                                        variables: {
                                                                            filter: {
                                                                                userId: getUserId()
                                                                            }
                                                                        }
                                                                    })}
                                                                >
                                                                    <IconButton size="small"> <EditIcon /> </IconButton>
                                                                </ModalEdit>
                                                                <ModalDelete id={booking.id} refresh={() => getBookings({
                                                                    variables: {
                                                                        filter: {
                                                                            userId: getUserId()
                                                                        }
                                                                    }
                                                                })}>
                                                                    <IconButton size="small"> <CloseIcon /> </IconButton>
                                                                </ModalDelete>
                                                            </>

                                                        }
                                                        {/* TODO: send files insted of services */}
                                                        {
                                                            booking.files.length > 0 &&

                                                            <ModalDownload files={booking.files} >
                                                                <IconButton size="small"> <DownloadIcon /> </IconButton>
                                                            </ModalDownload>
                                                        }
                                                    </div>
                                                </TableCell>
                                            </TableRow>)
                                        }
                                    </TableBody>

                                    <TableFooter>
                                        {/* <TableRow> */}
                                        <TablePagination
                                            // colSpan={3}
                                            rowsPerPage={10}
                                            rowsPerPageOptions={[10, 25, 50, { label: "Todas", value: -1 }]}
                                            count={data?.countBookings}
                                            rowsPerPage={pagination.rows}
                                            page={pagination.page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={(e) => handleChangePagination("rows", e.target.value)}
                                        // ActionsComponent={TablePaginationActions}
                                        />
                                        {/* </TableRow> */}
                                    </TableFooter>
                                </Table>
                            </TableContainer>


                        </div>
                        :
                        <div className="row col-12 aling-items-center justify-content-center">
                            <Typography>{intl.formatMessage({ id: "NO_BOOKINGS_YET" })}</Typography>
                        </div>
                    :

                    error ?
                        <div className="row col-12 aling-items-center justify-content-center">
                            <Typography>{intl.formatMessage({ id: "LOAD_BOOKINGS.ERROR" })}</Typography>
                        </div>
                        :
                        <div className="row m-4">
                            <CircularProgress className="m-auto" />
                        </div>
                }
            </Paper>
        </div >
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        borderRadius: 10,
    },
    heading: {
        flexShrink: 0,
        fontSize: 20,
        paddingLeft: 15,
        backgroundColor: 'transparent',
        marginTop: "auto",
        marginBottom: "auto"
    },
    card: {
        // display: "felx",
        // flexDirection: "column",
        border: "1px solid",
        borderColor: "#80808054",
        borderRadius: 10,
        padding: 10,
        height: '100%',
    }
}));