import React, { useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from '@material-ui/core/';
import { Typography, Modal } from '@material-ui/core';

import { useMutation } from '@apollo/client';
import { DELETE_BOOKING } from '../../../../graphql/mutations'
import Button from '../../../../components/Button';


export default function DeleteBooking(props) {

    const intl = useIntl();
    const classes = useStyles();

    const [openModal, setOpen] = useState(false);

    const [deleteBooking] = useMutation(DELETE_BOOKING, {
        onCompleted(res) {
            setOpen(false); 
            props.refresh();
        }
    });

    return (
        <div>
            <span onClick={() => setOpen(true)}>{props.children}</span>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openModal}
                onClose={() => setOpen(false)}
            >

                <div className={classes.paper}>
                    <div style={{ justifyContent: 'right', }}>
                        <Typography id="modal-title" style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
                            {intl.formatMessage({ id: "MODAL_DELETE.TITLE" })}
                        </Typography>
                        <Typography id="simple-modal-description" style={{ textAlign: 'center' }}>
                            {intl.formatMessage({ id: "MODAL_DELETE.DESCRIPTION" })}
                        </Typography>
                        <div className={'row  col-12 justify-content-center'} >
                            <div className="col-md-6 col-sm-12 justify-content-center">
                                <Button variant="contained" color="primary"
                                    onClick={() => deleteBooking({
                                        variables: {
                                            id: props.id
                                        }
                                    })}
                                >
                                    <Typography variant='h6'>{intl.formatMessage({ id: "MODAL_DELETE.BUTTON_TEXT" })}</Typography>
                                </Button>
                            </div>
                            <div className="col-md-6 col-sm-12 justify-content-center">
                                <Button variant="outlined" color="primary"
                                    onClick={() => setOpen(false)}
                                >
                                    <Typography variant='h6'>{intl.formatMessage({ id: "MODAL_DELETE.BUTON_CLOSE" })}</Typography>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        borderRadius: 10,
    },
}));