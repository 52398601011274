import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Typography, TextField, MenuItem, Paper } from "@material-ui/core";
import Button from "../../../../components/Button";
import Downshift from "downshift";
import axios from '../../../../redux/setupAxios';
import { useMutation } from "@apollo/client";
import { SIGN_UP } from "../../../../graphql/mutations";
import { setTokens } from "../hooks/auth";
import { useHistory } from "react-router-dom";


const initialValues = {
  fullname: "",
  email: "",
  phone: "",
  password: "",
  confrimPassword: "",
  dni: "",
};

function SignUp(props) {

  const history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [addressLabel, setAddressLabel] = useState("");
  const [addressData, setAddressData] = useState(null);
  const [isSearching, setSearching] = useState(false);
  const [searchAddress, setSearchAddress] = useState([])
  const [error, setError] = useState(false);
  const [signUp] = useMutation(SIGN_UP);

  const SignUpSchema = Yup.object().shape({
    fullname: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email(intl.formatMessage({
        id: "AUTH.VALIDATION.EMAIL_FORMAT",
      }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phone: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    dni: Yup.string()
      .test("dni", "introduce un dni valido",
        function (value) {
          let pattern = /^[0-9]{8,8}[A-Za-z]$/;
          return pattern.test(value);
        }
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confrimPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Las contraseñas no coinciden"
        ),
      }),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: SignUpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      signUp({
        variables: {
          input: {
            email: values.email,
            password: values.password,
            phone: values.phone.toString(),
            fullName: values.fullname,
            nif: values.dni,
            direction: addressData.properties.street,
            details: addressData.properties.housenumber,
            city: addressData.properties.locality,
            postalCode: addressData.properties.postalcode,
            country: addressData.properties.country,
            location: addressData.geometry
          }
        }
      }).then((res) => {
        setTokens(
          res.data.signUp.access_token,
          res.data.signUp.refresh_token
        );
        history.push('/');
        disableLoading();
      }).catch((e) => {
        disableLoading();
        setError(true);
      });;
    },
  });

  const getAddress = (text) => {
    axios
      .get('&boundary.country=ES&layers=address&text=' + text,
    )
      .then((response) => {
        setSearching(false);
        setSearchAddress(response.data.features);
      })
      .catch((e) => {
        console.log("error:", e);
      })
  }

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (addressLabel !== '' && isSearching) {
        getAddress(addressLabel);
      }
    }, 500)

    return () => clearTimeout(delaySearch)
  }, [addressLabel, isSearching])


  const selectAddress = (selection) => {
    setSearching(false);
    setAddressLabel(selection.properties.label);
    setAddressData(selection);
  }

  return (
    <div className=" login-signin m-auto" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-15">
        <Typography variant='h3'>
          {intl.formatMessage({ id: "AUTH.REGISTER.TITLE" })}
        </Typography>
        <Typography className='text-muted'>
          {intl.formatMessage({ id: "AUTH.REGISTER.SUBTITLE" })}
        </Typography>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {error && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })}</div>
          </div>
        )}
        {/* end: Alert */}

        <div className="row">

          <div className="col-12 mb-5">
            <Typography variant='h2'>
              {intl.formatMessage({ id: "AUTH.REGISTER.USER_DATA" })}
            </Typography>
          </div>

          <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
            <TextField
              variant='outlined'
              label={intl.formatMessage({ id: "AUTH.FULL_NAME" })}
              type="text"
              className={`form-control h-auto ${getInputClasses(
                "fullname"
              )}`}
              name="fullname"
              {...formik.getFieldProps("fullname")}
              error={formik.errors.fullname}
              helperText={formik.touched.fullname && formik.errors.fullname ?
                ''
                :
                <> {formik.errors.fullname}</>
              }
            />
          </div>

          <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
            <TextField
              variant='outlined'
              label={intl.formatMessage({ id: "AUTH.EMAIL" })}
              type="email"
              className={`form-control h-auto ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
              error={formik.errors.email}
              helperText={formik.touched.email && formik.errors.email ?
                ''
                :
                <> {formik.errors.email}</>
              }
            />
          </div>

          <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
            <TextField
              variant='outlined'
              label={intl.formatMessage({ id: "AUTH.PHONE" })}
              type="number"
              className={`form-control h-auto ${getInputClasses(
                "phone"
              )}`}
              name="phone"
              {...formik.getFieldProps("phone")}
              error={formik.errors.phone}
              helperText={formik.touched.phone && formik.errors.phone ?
                ''
                :
                <> {formik.errors.phone}</>
              }
            />
          </div>

          <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
            <TextField
              variant='outlined'
              label={intl.formatMessage({ id: "LABEL.INPUT_DNI" })}
              type="text"
              className={`form-control h-auto ${getInputClasses(
                "dni"
              )}`}
              name="dni"
              {...formik.getFieldProps("dni")}
              error={formik.errors.dni}
              helperText={formik.touched.dni && formik.errors.dni ?
                ''
                :
                <> {formik.errors.dni}</>
              }
            />
          </div>

          {/* begin: Password */}
          <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
            <TextField
              variant='outlined'
              label={intl.formatMessage({ id: "AUTH.PASSWORD" })}
              type="password"
              className={`form-control h-auto ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
              error={formik.errors.password}
              helperText={formik.touched.password && formik.errors.password ?
                ''
                :
                <> {formik.errors.password}</>
              }
            />
          </div>
          {/* end: Password */}

          {/* begin: Confirm Password */}
          <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
            <TextField
              variant='outlined'
              label={intl.formatMessage({ id: "AUTH.CONFIRM_PASSWORD" })}
              type="password"
              className={`form-control h-auto ${getInputClasses(
                "confrimPassword"
              )}`}
              name="confrimPassword"
              {...formik.getFieldProps("confrimPassword")}
              error={formik.errors.confrimPassword}
              helperText={formik.touched.confrimPassword && formik.errors.confrimPassword ?
                ''
                :
                <> {formik.errors.confrimPassword}</>
              }
            />
          </div>
          {/* end: Confirm Password */}

          <div className="col-12 mb-5">
            <Typography variant='h2'>
              {intl.formatMessage({ id: "AUTH.REGISTER.ADDRESS_DATA" })}
            </Typography>
          </div>

          <div className='col-12 mb-4'>
            <div className={classes.root}>
              <Downshift
                inputValue={addressLabel}
                onOuterClick={() => { }}
                onInputValueChange={event => {
                  setSearching(true);
                  setAddressLabel(event);
                  setAddressData(null)
                }}
                onChange={(selection) =>
                  selectAddress(selection)
                }
                itemToString={(item) => (item ? item.value : '')}
              >
                {({
                  getInputProps,
                  getItemProps,
                  getMenuProps,
                  isOpen,
                  inputValue,
                  highlightedIndex,
                  selectedItem,
                }) => (
                  <div className={classes.container}>
                    <TextField
                      fullWidth={true}
                      variant='outlined'
                      id="addressLabel"
                      name="addressLabel"
                      label={intl.formatMessage({ id: "LABEL.INPUT_ADDRESS" })}
                      value={inputValue}
                      onChange={setAddressLabel}
                      InputProps={{
                        endAdornment: isSearching && <CircularProgress style={{ height: 20, width: 20 }} />
                      }}
                      {...getInputProps()} />
                    <div {...getMenuProps()}>
                      {isOpen
                        ?
                        <Paper className={classes.paper} square>
                          {
                            searchAddress.map(
                              (item, index) =>
                                <MenuItem
                                  {...getItemProps({
                                    key: item.properties.id,
                                    index,
                                    item,
                                    style: {
                                      backgroundColor:
                                        highlightedIndex === index ? 'lightgray' : 'white',
                                      fontWeight: selectedItem === item ? 'bold' : 'normal',
                                    },
                                  })}
                                >
                                  {item.properties.label}
                                </MenuItem>
                            )
                          }
                        </Paper>
                        : null}
                    </div>
                  </div>
                )}
              </Downshift>
            </div>

          </div>

          {
            addressData &&
            <>
              <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
                <TextField
                  fullWidth
                  value={addressData.properties.street}
                  disabled
                  variant='outlined'
                  label={intl.formatMessage({ id: "LABEL.INPUT_PERSONAL_ADDRESS" })}
                  type="text"
                  name="street"
                />
              </div>
              <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
                <TextField
                  fullWidth
                  label={intl.formatMessage({ id: "LABEL.INPUT_POSTALCODE" })}
                  disabled
                  value={addressData.properties.housenumber}
                  variant='outlined'
                  name="street"
                />
              </div>
              <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
                <TextField
                  fullWidth
                  value={addressData.properties.locality}
                  disabled
                  variant='outlined'
                  label={intl.formatMessage({ id: "LABEL.INPUT_CITY" })}
                  type="text"
                  name="street"
                />
              </div>
              <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
                <TextField
                  fullWidth
                  disabled
                  value={addressData.properties.postalcode}
                  variant='outlined'
                  label={intl.formatMessage({ id: "LABEL.INPUT_POSTALCODE" })}
                  type="text"
                  name="street"
                // {...formik.getFieldProps("street")}
                />
              </div>
              <div className="form-group fv-plugins-icon-container col-md-6 col-sm-12">
                <TextField
                  fullWidth
                  disabled
                  value={addressData.properties.country}
                  variant='outlined'
                  label={intl.formatMessage({ id: "LABEL.INPUT_COUNTRY" })}
                  type="text"
                  name="street"
                />
              </div>
            </>
          }
        </div>

        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!addressData}
            className="ml-auto mr-auto"
          >
            <span>{intl.formatMessage({ id: "AUTH.SIGNUP_BUTTON" })}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mb-0">
          <Link
            to="/auth/login"
            className="text-dark-50 text-hover-primary mt-5 ml-auto mr-auto"
            id="kt_login_forgot"
          >
            {intl.formatMessage({ id: "AUTH.SIGN_UP.GO_BACK" })}
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(SignUp);


const useStyles = makeStyles(theme => ({

  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  root: {
    flexGrow: 1,
    // height: 250
  },
}));