import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getToken } from "../app/modules/Auth/hooks/auth";

export const ERROR_USER_NOT_FOUND = "Not found.";
export const ERROR_EMAIL_NOT_VERIFIED = "Email not verified.";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL + '/graphql',
});

const X_API_KEY = process.env.REACT_APP_API_KEY_GRAPHQL ;

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-api-key": X_API_KEY,
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
  },
};

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
