import React from "react";
import { Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";

import { MainPage } from "./pages/main/MainPage";
import { BookingsPage } from "./pages/bookings/BookingsPage";

import { FormDataProvider } from './context/FormContext';


export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <FormDataProvider>
            {/* <Suspense fallback={<LayoutSplashScreen />}> */}
                <Switch>
                    {
                        // /* Redirect from root URL to /dashboard. */
                        // <Redirect exact from="/" to="/dashboard"/>
                    }
                    <ContentRoute path="/bookings" component={BookingsPage} />
                    <ContentRoute path="/" component={MainPage} />
                    {/* <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/> */}
                </Switch>
            {/* </Suspense> */}
        </FormDataProvider>
    );
}
