import {
  API_KEY_GEOCODE,
} from "../app/constants/strings";
import axiosBase from "axios";

axiosBase.defaults.headers.common['Content-Type'] = 'application/json';
// axiosBase.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const axios = axiosBase.create({
  baseURL: 'https://api.openrouteservice.org/geocode/autocomplete?api_key=' + API_KEY_GEOCODE,
  headers: {
    'Accept': 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8'
  }
});

axios.CancelToken = axiosBase.CancelToken;
axios.isCancel = axiosBase.isCancel;

export default axios;