import React, { useState } from "react";

export const FormDataContext = React.createContext({});

export const FormDataProvider = (props) => {

    const initialData = {
        service1: null,
        service2: null,
        services: [],
        selectedSlot: null,
        scheduledIn: null,
        comments: "",
        petName: '',
        ownerName: '',
        species: '',
        petWeight: '',
        petAge: '',
    }

    const [data, setData] = useState(initialData)
    const [isValid, setValid] = useState(false);
    const [validations, setValidations] = useState({
        serviceId: false,
        profesionalId: true,
        date: true,
        scheduledIn: true,
        comments: true,
        petName: true,
        ownerName: true,
        species: true,
        petWeight: true,
        petAge: true,
    });

    const validate = (name, value) => {
        switch (name) {
            case 'serviceId':
            case 'profesionalId':
            case 'date':
            case 'scheduledIn':
                return value !== null;
            case 'petName':
            case 'ownerName':
            case 'species':
            case 'petAge':
            case 'petWeight':
                return value !== null && value !== "";
            case 'comments':
            default:
                return true;
        }
    };

    const handleChange = (name) => (event) => {
        let currentData = { ...data };

        if (name === 'selectedSlot') {
            currentData[name] = event;
        } else if (name === "service1") {
            currentData[name] = event.target.value;
            currentData["service2"] = null;
        } else {
            currentData[name] = event.target.value;
        }

        let currentValidations = { ...validations };
        currentValidations[name] = validate(name, currentData[name]);

        setData(currentData);
        setValid(setValidValue(currentData));
        setValidations(currentValidations);
    }

    const setValidValue = (currentData) => {
        let valid = true;
        for (let value of Object.entries(currentData)) {
            valid = valid && validate(value[0], value[1]);
            if (!valid) { break; }
        }
        return valid;
    };

    const getServices = () => {
        let services = [];

        if (data.service1 === data.service2) {
            services = [{
                id: data.service1,
                units: 2,
                petName: data.petName,
                ownerName: data.ownerName,
                species: data.species,
                petWeight: data.petWeight,
                petAge: data.petAge,
            }]
        } else {
            services = [{
                id: data.service1,
                units: 1,
                petName: data.petName,
                ownerName: data.ownerName,
                species: data.species,
                petWeight: data.petWeight,
                petAge: data.petAge,
            }];
            if (data.service2 !== null && data.service2 !== 0) {
                services.push({
                    id: data.service2,
                    units: 1,
                    petName: data.petName,
                    ownerName: data.ownerName,
                    species: data.species,
                    petWeight: data.petWeight,
                    petAge: data.petAge,
                })
            }
        }
        return services;
    }


    return (
        <FormDataContext.Provider
            value={{
                data,
                validations,
                isValid,
                handleChange,
                getServices
            }}
        >
            {props.children}
        </FormDataContext.Provider>
    );

}