import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from '@material-ui/core';
import { useIntl } from "react-intl";
import ExpansionPanel from '../../../../components/ExpansionPanel';
import ExpansionPanelSummary from '../../../../components/ExpansionPanelSummary';
import { ExpansionPanelDetails, CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import Button from '../../../../components/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormDataContext } from '../../../context/FormContext';


export default function PreferencesPanel(props) {

    const dataContext = useContext(FormDataContext);
    const { data, handleChange } = dataContext;

    const classes = useStyles();
    const intl = useIntl();
    const [isValid, setFormValid] = useState(false);
    const [filteredServices, setServices] = useState([]);

    useEffect(() => {
        if (data.service1 !== null) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [data])

    const handleContiune = () => {
        let selectedServices = props.data.services.filter(s => s.id === data.service1 || s.id === data.service2);
        if (selectedServices?.find(s => s.callToBook)) {
           props.handleModalCall()
        } else {
            props.handleExpand('panel2')(null, true);
        }

    }

    const handleChangeService = (event) => {
        let profesionals = props.data.services.find(s => s.id === event.target.value).profesionals;
        let services = [];
        props.data.services.map(s => {
            if (s.profesionals.some(p => profesionals.find(pro => pro.id === p.id)) && s.active) {
                services.push(s);
            }
        })

        setServices(services);
        handleChange('service1')(event);
    }

    return (
        <>
            <div>
                <ExpansionPanel square expanded={props.expanded === 'panel1'} disabled={props.disabled} onChange={props.handleExpand('panel1')}>
                    <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={props.expanded === 'panel1' ? null : <ExpandMoreIcon />}>
                        <span className="material-icons mt-auto mb-auto">
                            calendar_today
                        </span>
                        <Typography className={classes.heading}>{intl.formatMessage({ id: "TITLE_1" })}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails >
                        <div className='col-12'>
                            <div className='row pl-11 mb-4'>
                                <Typography >
                                    {intl.formatMessage({ id: "PANEL_1.DESCRIPTION" })}
                                </Typography>
                            </div>

                            {
                                props.loading ?
                                    <div className="d-flex justify-content-center">
                                        <CircularProgress />
                                    </div>
                                    :
                                    <div className={'row'}>
                                        <div className={' col-sm-12'}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-age-simple">
                                                    {/* ref={inputLabel}ref={inputLabel} */}
                                                    {intl.formatMessage({ id: "LABEL.SELECT_SERVICES" })}
                                                </InputLabel>
                                                <Select
                                                    value={data.service1}
                                                    onChange={handleChangeService}
                                                    input={<OutlinedInput labelWidth={230} name="service1" id="outlined-age-simple" />}
                                                >
                                                    {props.data.services.filter(s => s.active).map(service => <MenuItem value={service.id}>{service.title}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className={' col-sm-12'}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel htmlFor="outlined-age-simple">
                                                    {/* ref={inputLabel}ref={inputLabel} */}
                                                    {intl.formatMessage({ id: "LABEL.SELECT_SERVICES_SECOND" })}
                                                </InputLabel>
                                                <Select
                                                    disabled={filteredServices.length === 0}
                                                    value={data.service2}
                                                    onChange={handleChange('service2')}
                                                    input={<OutlinedInput labelWidth={300} name="service2" id="outlined-age-simple" />}
                                                >
                                                    <MenuItem value={0}>{"Continuar con un servicio"}</MenuItem>
                                                    {filteredServices.map(service => <MenuItem value={service.id}>{service.title}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className={' col-md-12 col-sm-12 '}>
                                            <Typography className="ml-3">{intl.formatMessage({ id: "TEXT.NO_BOOKING_AVAILABLE" })}</Typography>
                                        </div>

                                    </div>
                            }

                            <div className={'row justify-content-center'}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleContiune}
                                    disabled={!isValid}>
                                    <Typography variant='h6'>{intl.formatMessage({ id: "BUTTON.CHECK_AVAILABILITY" })}</Typography>
                                </Button>
                            </div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div >
            
        </>
    );
}

const useStyles = makeStyles(theme => ({
    heading: {
        flexShrink: 0,
        fontSize: 20,
        paddingLeft: 15,
        backgroundColor: 'transparent'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    root: {
        flexGrow: 1,
    },
}));