import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { Typography, TextField } from "@material-ui/core";
import Button from "../../../../components/Button";
import { RECOVER_PASSWORD } from "../../../../graphql/mutations";
import { useMutation } from "@apollo/client";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);

  const [recoverPassword] = useMutation(RECOVER_PASSWORD);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({
        id: "AUTH.VALIDATION.EMAIL_FORMAT",
      }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      recoverPassword({
        variables: {
          email: values.email
        }
      }).then((res) => {
        setSubmitting(false);
        setIsRequested(true);
      }).catch((err) => {
        setIsRequested(false);
        setSubmitting(false)
      });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot m-auto" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-15">
            <Typography variant='h3'>
              {intl.formatMessage({ id: "AUTH.RECOVER.TITLE" })}
            </Typography>
            <Typography className='text-muted'>
              {intl.formatMessage({ id: "AUTH.RECOVER.SUBTITLE" })}
            </Typography>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <TextField
                variant='outlined'
                label={intl.formatMessage({ id: "AUTH.EMAIL" })}
                type="email"
                className={`form-control h-auto ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
                error={formik.errors.email}
                helperText={formik.touched.email && formik.errors.email ?
                  ''
                  :
                  <> {formik.errors.email}</>
                }
              />
            </div>
            <div className="d-flex flex-wrap flex-center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="ml-auto mr-auto"
                // className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <span>{intl.formatMessage({ id: "AUTH.RECOVER_BUTTON" })}</span>
                {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
              </Button>
            </div>
            <div className="d-flex flex-wrap flex-center">
              <Link to="/auth"
                className="text-dark-50 text-hover-primary mt-5 ml-auto mr-auto"
                id="kt_login_forgot"
              >
                {intl.formatMessage({ id: "AUTH.SIGN_UP.GO_BACK" })}
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(ForgotPassword);
